import Model from '@/shared/classes/model'
import { RoleLevel } from "@/shared/modules/role/role.model";

export default class Tab {
  to!: string
  title!: string
  icon!: string
  permissions: string[] = []
  allowedForRoles: RoleLevel[] = []
  atLeastOne!: boolean
  entry: Model<any>|null = null
  isVisible: boolean = true

  activeCondition!: () => boolean

  setTo(to: string): this {
    this.to = to
    return this
  }

  setTitle(title: string): this {
    this.title = title
    return this
  }

  setPermissions(permissions: string[], atLeastOne: boolean = false, entry: Model<any>|null = null): this {
    this.permissions = permissions
    this.atLeastOne = atLeastOne
    this.entry = entry
    return this
  }

  setAllowedForRoles(allowedForRoles: RoleLevel[]): this {
    this.allowedForRoles = allowedForRoles
    return this
  }

  setIcon(icon: string): this {
    this.icon = icon
    return this
  }

  setIsVisible(isVisible: boolean): this {
    this.isVisible = isVisible
    return this
  }

  setActiveCondition(activeCondition: () => boolean): this {
    this.activeCondition = activeCondition
    return this
  }
}
